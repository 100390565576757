//3次メッシュ表示

import React from 'react';
import {Link} from 'gatsby'
import {Viewer,UrlTemplateImageryProvider,Credit,KmlDataSource,HeadingPitchRange, JulianDate,Rectangle, Sandcastle, Clock, ClockRange, ClockStep, ClockViewModel, ImagerySplitDirection, ScreenSpaceEventHandler,ScreenSpaceEventType, Math, Cartesian2, Cartesian3, Transforms, Camera, Color } from 'cesium';
import 'cesium/Source/Widgets/widgets.css';
import CesiumNavigation from "cesium-navigation-es6";
import { backgroundColor, shadow } from 'styled-system';
import { buttonshitakml, buttononoffkml, canvaswrap, slider, buttonshita, radiobutton, buttononoff,  } from "./cesium.module.css";

import Cityset1 from "../citysetting/citytitle1"
import { withTheme } from 'styled-components';
import { Entity, ShadowMode } from 'cesium';


class CesiumContainer extends React.Component {
  divRef = React.createRef();
  viewer = null;
  entity = null;

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>

        <div className={canvaswrap}>
          <div id="cesiumcontainerdiv">
            <div ref={this.divRef} style={{height:this.props.height}}>
              <div id="slider" className={slider}></div>
            </div>
          </div>
        </div>


        <div id="r"></div>


        <div style={{display:"flex",justifyContent: "space-between"}}>





          <div className={buttonshita}>
            <input className={radiobutton} type="radio" name="yearbutton" id="timeset1" value={this.props.year1}/>
            <label htmlFor="timeset1">{this.props.year1}</label>
          </div>

          <div className={buttonshita}>
            <input className={radiobutton} type="radio" name="yearbutton" id="timeset2" value={this.props.year2}/>
            <label htmlFor="timeset2">{this.props.year2}</label>
          </div>

          <div className={buttonshita}>
          <input className={radiobutton} type="radio" name="yearbutton" id="timeset3" value={this.props.year3}/>
            <label htmlFor="timeset3">{this.props.year3}</label>
          </div>

          <div className={buttonshita}>
          <input className={radiobutton} type="radio" name="yearbutton" id="timeset4" value={this.props.year4}/>
            <label htmlFor="timeset4">{this.props.year4}</label>
          </div>

          <div className={buttonshita}>
          <input className={radiobutton} type="radio" name="yearbutton" id="timeset5" value={this.props.year5}/>
            <label htmlFor="timeset5">{this.props.year5}</label>
          </div>

        </div>





        <div style={{display:"flex",justifyContent: "space-between"}}>

          <div className={buttonshita}>
            <div className={buttononoff} type="button" id="originview" value="はじめに戻る">はじめに戻る</div>
          </div>

          <div className={buttonshita}>
          <div className={buttononoff} type="button" id="rotate" value="回転させる">回転させる</div>
          </div>

          <div className={buttonshita}>
            <Link
              className={buttononoff}
              to={this.props.full_url}
              // to={"/"+this.props.no+"/kokudof"}
              kml_kml={this.props.kml_kml}
              kml_kmz={this.props.kml_kmz}
              // dir="/kml/sample.kml"
              width="100%"
              height="70vh"
              no={this.props.no}
            >
            {this.props.full_name}
            </Link>
          </div>

          <div className={buttonshita}>
            <div type="button" id="googlemapopen"  className={buttononoff} value="場所を確認する">場所を確認する</div>
          </div>

          <div className={buttonshita}>
          <input className={radiobutton} type="radio" name="yearbutton" id="timesetoff" value="背景だけを表示"/>
            <label for="timesetoff">背景だけを表示</label>
          </div>

        </div>
      </div>
    );
  }

  componentDidMount() {

    // var clock = new Clock({
    //     startTime : JulianDate.fromIso8601('1985-08-30T11:56:04+08'),
    //     stopTime : JulianDate.fromIso8601('2035-08-30T11:56:04+08'),
    //     currentTime : JulianDate.fromIso8601('2017-12-30T11:56:04+08'),
    //     multiplier : 1,
    //     shouldAnimate : true, 
    //     clockRange : ClockRange.LOOP_STOP,
    //     clockStep : ClockStep.SYSTEM_CLOCK_MULTIPLIER
    // });

    // var extent = Rectangle.fromDegrees(122, 20, 153, 45);
    // Camera.DEFAULT_VIEW_RECTANGLE = extent;
    // Camera.DEFAULT_VIEW_FACTOR = 0;


/// ビューア起動 ///////
const viewer = new Viewer(this.divRef.current, {
  imageryProvider: 
  new UrlTemplateImageryProvider({
    url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
    // url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
    credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
  }),
  baseLayerPicker: false,
  timeline : false,
  animation : false,
  fullscreenButton: false,
  homeButton: false,
  vrButton: false,
  geocoder:false,
  sceneModePicker:false,
  navigationHelpButton:false,
  infoBox : false,
  selectionIndicator : false,
  shadows : false,
  // clockViewModel: new ClockViewModel(clock)
});
/////// ビューア起動 ///

/// CSVデータ読み込み ///////
var data1995 = viewer.entities.add(new Entity());
var data2000 = viewer.entities.add(new Entity());
var data2005 = viewer.entities.add(new Entity());
var data2010 = viewer.entities.add(new Entity());
var data2015 = viewer.entities.add(new Entity());

var min = this.props.min;
var ratio = this.props.ratio;
var shikii1 = this.props.shikii1;
var shikii2 = this.props.shikii2;
var shikii3 = this.props.shikii3;
var hanrei1_color = this.props.hanrei1_color;
var hanrei2_color = this.props.hanrei2_color;
var hanrei3_color = this.props.hanrei3_color;
var hanrei4_color = this.props.hanrei4_color;
var csv = this.props.csv
var csvmode = this.props.csvmode

var ratio = Number(ratio);
var shikii1 = Number(shikii1);
var shikii2 = Number(shikii2);
var shikii3 = Number(shikii3);


/// /// １.2列目の閾値で色分け /////////////////////////
// function getJapan3rdmeshRectangle( meshcode3rd , h, color_gbraaa , description_text){
function mesh3rdRect1( meshcode3rd , h, c){
  var strMeshcoe = String(meshcode3rd);
  var p = parseInt(strMeshcoe.slice(0,2));
  var u = parseInt(strMeshcoe.slice(2,4));
  var q = parseInt(strMeshcoe.slice(4,5));
  var v = parseInt(strMeshcoe.slice(5,6));
  var r = parseInt(strMeshcoe.slice(6,7));
  var w = parseInt(strMeshcoe.slice(7,8));
  var lat = p / 1.5 + q / 12 + r / 120;
  var lon = u + 100 + v * 0.125 + w * 0.0125;

    var color1;
    if (h >= shikii1) {
      color1 = hanrei1_color;
      // color1 = "#67ADDF";
    } else if (h >= shikii2) {
      color1 = hanrei2_color;
    } else if (h >= shikii3) {
      color1 = hanrei3_color;
    } else {
      color1 = hanrei4_color;
    }

  var ans_entities = {
          // parent: data1995,
          // id:meshcode3rd ,
          // description : "<p>"+description_text+"</p>",
          // rectangle : {
              coordinates : Rectangle.fromDegrees( lon , lat , lon+0.0125 , lat+0.008333333 ),
              height : 0,
              extrudedHeight : h*ratio,
              material : Color.fromCssColorString(color1),
              // material : Color.fromRgba( color_gbra )
              outline : true,
              outlineColor : "white",
              outlineWidth : 1,
              // shadows : ShadowMode.ENABLED
          // }
      };
  return ans_entities ;
}
/// ///////////////////////// １.2列目の閾値で色分け ///
/// /// 2.3列目の値で色分け /////////////////////////
// function getJapan3rdmeshRectangle( meshcode3rd , h, color_gbraaa , description_text){
  function mesh3rdRect2( meshcode3rd , h, c){
    var strMeshcoe = String(meshcode3rd);
    var p = parseInt(strMeshcoe.slice(0,2));
    var u = parseInt(strMeshcoe.slice(2,4));
    var q = parseInt(strMeshcoe.slice(4,5));
    var v = parseInt(strMeshcoe.slice(5,6));
    var r = parseInt(strMeshcoe.slice(6,7));
    var w = parseInt(strMeshcoe.slice(7,8));
    var lat = p / 1.5 + q / 12 + r / 120;
    var lon = u + 100 + v * 0.125 + w * 0.0125;
  
      var color1;
      if (c == 3) {
        color1 = hanrei1_color;
        // color1 = "#67ADDF";
      } else if (c == 2) {
        color1 = hanrei2_color;
      } else if (c == 1) {
        color1 = hanrei3_color;
      } else {
        color1 = hanrei4_color;
      }
  
    var ans_entities = {
            // parent: data1995,
            // id:meshcode3rd ,
            // description : "<p>"+description_text+"</p>",
            // rectangle : {
                coordinates : Rectangle.fromDegrees( lon , lat , lon+0.0125 , lat+0.008333333 ),
                height : 0,
                extrudedHeight : h*ratio,
                material : Color.fromCssColorString(color1),
                // material : Color.fromRgba( color_gbra )
                outline : true,
                outlineColor : "white",
                outlineWidth : 1,
                // shadows : ShadowMode.ENABLED
            // }
        };
    return ans_entities ;
  }

/// ///////////////////////// 2.3列目の値で色分け ///

/// /// 3.2列目の閾値で色分け /////////////////////////
// function getJapan3rdmeshRectangle( meshcode3rd , h, color_gbraaa , description_text){
  function mesh3rdRect3( meshcode3rd , h, c){
    var strMeshcoe = String(meshcode3rd);
    var p = parseInt(strMeshcoe.slice(0,2));
    var u = parseInt(strMeshcoe.slice(2,4));
    var q = parseInt(strMeshcoe.slice(4,5));
    var v = parseInt(strMeshcoe.slice(5,6));
    var r = parseInt(strMeshcoe.slice(6,7));
    var w = parseInt(strMeshcoe.slice(7,8));
    var lat = p / 1.5 + q / 12 + r / 120;
    var lon = u + 100 + v * 0.125 + w * 0.0125;
  

      var color1;
      if (c >= shikii1) {
        color1 = hanrei1_color;
        // color1 = "#67ADDF";
      } else if (c >= shikii2) {
        color1 = hanrei2_color;
      } else if (c >= shikii3) {
        color1 = hanrei3_color;
      } else {
        color1 = hanrei4_color;
      }
  
    var ans_entities = {
            // parent: data1995,
            // id:meshcode3rd ,
            // description : "<p>"+description_text+"</p>",
            // rectangle : {
                coordinates : Rectangle.fromDegrees( lon , lat , lon+0.0125 , lat+0.008333333 ),
                height : 0,
                extrudedHeight : h*ratio,
                material : Color.fromCssColorString(color1),
                // material : Color.fromRgba( color_gbra )
                outline : true,
                outlineColor : "white",
                outlineWidth : 1,
                // shadows : ShadowMode.ENABLED
            // }
        };
    return ans_entities ;
  }
  /// ///////////////////////// 3.2列目の閾値で色分け ///





function getCSV(no,year,dataname) {
  var req = new XMLHttpRequest();
  req.open('get', csv+"_"+year+".csv", true);
  req.send(null);
  req.onload = function() {
    // setCSV(req.responseText);
    //ここでlocalStorageへ
    var dataArr;
    // var r = document.getElementById('r');
    var tmp = req.responseText.split('\n');
    // console.log(tmp.length);

    // var firstview = viewer.entities.add(mesh3rdRect1(tmp[0].substr(0,6),tmp[0].substr(7))).then(
    //   viewer.flyTo(viewer.entities)
    // )
    // .catch(failureCallback);

    if (csvmode == "color_shikii") {

    for(let i = 0, len = tmp.length; i< len ; i++){
      dataArr = tmp[i].split(',');
      var minmin = Number(min);
      if (dataArr[1]>minmin) {
        viewer.entities.add(
          {
            parent: dataname,
            // id:meshcode3rd ,
            // description : "<p>"+description_text+"</p>",
            rectangle: mesh3rdRect3(dataArr[0],dataArr[1],dataArr[2])
          }
        );
      // viewer.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
      // if(tmp[0]){
      // }
      }
    }

  } else if(shikii1 !== 0){

      for(let i = 0, len = tmp.length; i< len ; i++){
        dataArr = tmp[i].split(',');
        var minmin = Number(min);
        if (dataArr[1]>minmin) {
          viewer.entities.add(
            {
              parent: dataname,
              // id:meshcode3rd ,
              // description : "<p>"+description_text+"</p>",
              rectangle: mesh3rdRect1(dataArr[0],dataArr[1],dataArr[2])
            }
          );
        // viewer.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
        // if(tmp[0]){
        // }
        }
      }
  
  

  } else {

    for(let i = 0, len = tmp.length; i< len ; i++){
      dataArr = tmp[i].split(',');
      var minmin = Number(min);
      if (dataArr[1]>minmin) {
        viewer.entities.add(
          {
            parent: dataname,
            // id:meshcode3rd ,
            // description : "<p>"+description_text+"</p>",
            rectangle: mesh3rdRect2(dataArr[0],dataArr[1],dataArr[2])
          }
        );
      // viewer.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
      // if(tmp[0]){
      // }
      }
    }

  }





    viewer.zoomTo(viewer.entities);
    
        // tmp.forEach(x => {
        //   dataArr = x.split(',');
        //   if (dataArr[1]>10000) {
        //     viewer.entities.add(mesh3rdRect1(dataArr[0],dataArr[1]));
        //   }
        // });
    
  };
}



getCSV(this.props.no,this.props.year1, data1995);
getCSV(this.props.no,this.props.year2, data2000);
getCSV(this.props.no,this.props.year3, data2005);
getCSV(this.props.no,this.props.year4, data2010);
getCSV(this.props.no,this.props.year5, data2015);
// getCSV(101,2015, data2015);
data1995.show = true;
data2000.show = false;
data2005.show = false;
data2010.show = false;
data2015.show = false;
document.getElementById('timeset1').checked = true;




// let meshdata = [
//   [50302089, 10000],
//   [50302078, 5000],
//   [50302066, 1000],
//  ]
 
// console.log(meshdata[0]);

// var meshmesh = getJapan3rdmeshRectangle(meshdata[0][0],meshdata[0][1]);
// var meshmesh2 = getJapan3rdmeshRectangle( 50302078 , 5000);
// var meshmesh3 = getJapan3rdmeshRectangle( 50302066 , 5000);

// var wyoming = viewer.entities.add(meshmesh);
// var wyoming = viewer.entities.add(meshmesh2);
// var wyoming = viewer.entities.add(meshmesh3);
// viewer.zoomTo(wyoming);

/////// CSVデータ読み込み ///





/// セシウムのロゴを消す ///////
    viewer._cesiumWidget._creditContainer.style.display = "none";
/////// セシウムのロゴを消す ///

/// スライダー //////////
var layers = viewer.imageryLayers;
var chiriinMap = layers.addImageryProvider(
  new UrlTemplateImageryProvider({
    // url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
    url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
//      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
    credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
  }),
);
chiriinMap.splitDirection = ImagerySplitDirection.LEFT;
var slider = document.getElementById("slider");
viewer.scene.imagerySplitPosition = slider.offsetLeft / slider.parentElement.offsetWidth;
var handler = new ScreenSpaceEventHandler(slider);
var moveActive = false;
function move(movement){
  if(!moveActive){
    return;
  }
var relativeOffset = movement.endPosition.x;
var splitPosition = (slider.offsetLeft + relativeOffset) / slider.parentElement.offsetWidth;
slider.style.left = 100.0 * splitPosition + "%";
viewer.scene.imagerySplitPosition = splitPosition;
}
handler.setInputAction(function () {
  moveActive = true;
}, ScreenSpaceEventType.LEFT_DOWN);
handler.setInputAction(function () {
  moveActive = true;
}, ScreenSpaceEventType.PINCH_START);
handler.setInputAction(move, ScreenSpaceEventType.MOUSE_MOVE);
handler.setInputAction(move, ScreenSpaceEventType.PINCH_MOVE);
handler.setInputAction(function () {
  moveActive = false;
}, ScreenSpaceEventType.LEFT_UP);
handler.setInputAction(function () {
  moveActive = false;
}, ScreenSpaceEventType.PINCH_END);
////////// スライダー ///

/// GoogleMapを開く //////////
var win;
var buttongoogle = document.getElementById('googlemapopen');
buttongoogle.addEventListener('click', function() {
  var cartographic = viewer.camera.positionCartographic;
          var lon = Math.toDegrees(cartographic.longitude).toFixed(5);
          var lat = Math.toDegrees(cartographic.latitude).toFixed(5); 
          var url = 'http://maps.google.co.jp/maps?q=' + lat + ',' + lon;
          win = window.open(url ,"gmap" ,'width=800,height=600');
}, false);
////////// GoogleMapを開く ///

/// timelineをtrueにしたまま画面から消す //////////
    // viewer.timeline.container.style.display = 'none';
    // viewer.timeline.resize();
////////// timelineをtrueにしたまま画面から消す ///

/// 地形へのライティングを使う //////////
// viewer.scene.globe.enableLighting = true;
////////// 地形へのライティングを使う ///


/// ONOFF用のタイムライン延長KML準備 ///////
// var kmlaaa = '<?xml version="1.0" encoding="UTF-8" ?><kml xmlns="http://earth.google.com/kml/2.0"><Placemark><TimeSpan><begin>'+'1985'+'-10-01'+'</begin><end>'+'2035'+'-10-01'+'</end></TimeSpan><styleUrl>#PolyCol3</styleUrl><Polygon><altitudeMode>relativeToGround</altitudeMode><outerBoundaryIs><LinearRing><coordinates></coordinates></LinearRing></outerBoundaryIs></Polygon></Placemark></kml>';
// var urlaaa = URL.createObjectURL(new Blob([kmlaaa]));
/////// ONOFF用のタイムライン延長KML準備 ///

/// KMLデータ準備 ///////
//     var options = {
//       camera: viewer.scene.camera,
//       canvas: viewer.scene.canvas,
//     };
//     var dataSource1 = new KmlDataSource.load(this.props.kml_kml,options);
// /////// KMLデータ準備 ///

// /// KMLデータ読み込み ///////
// viewer.dataSources.add(dataSource1).show = true;
// viewer.zoomTo(dataSource1
//   // , new HeadingPitchRange(0, -1, 2000000)
//   );
// viewer.dataSources.add(KmlDataSource.load(urlaaa,options));
/////// KMLデータ読み込み ///

























/// 回転ボタン //////////
// document.getElementById('rotate').innerHTML =
// '<div className={buttononoff} type="button" id="rotate" value="回転させる">回転させる</div>';
var buttona = document.getElementById('rotate');
buttona.addEventListener('click', function() {
    let currentvalue = document.getElementById('rotate').value;
    if (currentvalue == "回転させる") {
        document.getElementById("rotate").value="Stop"
        viewer.clock.onTick.addEventListener(function(clock) {
          viewer.scene.camera.rotateRight(-0.005);
        });
    } else {
          document.getElementById("rotate").value="回転させる"
          viewer.zoomTo(viewer.entities).then(
              function(){
              var cartographic = viewer.camera.positionCartographic;
              var center = Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude+cartographic.height*1.52849689e-7, 100);
              //latitude > +0.003
              var transform = Transforms.eastNorthUpToFixedFrame(center);
              viewer.scene.camera.lookAtTransform(transform, new HeadingPitchRange(0, -0.5, cartographic.height*1.5));
              viewer.clock.onTick.addEventListener(function(clock) {
                viewer.scene.camera.rotateRight(0.005);
              });
              }
          )
          ;
    }
}, false);
////////// 回転ボタン ///

/// KMLのON/OFFボタン //////////
    // document.getElementById('toolbar2').innerHTML =
    // '<div className={buttononoff}><input type="button" id="onoff" value="Off"/></div>';

    // var buttona = document.getElementById('onoff');
    // buttona.addEventListener('click', function() {
    //     let currentvalue = document.getElementById('onoff').value;
    //     // buttona state changed.
    //     if (currentvalue == "On") {
    //         // Show if not shown.
    //         document.getElementById("onoff").value="Off"
    //         if (!viewer.dataSources.contains(dataSource1)) {
    //             viewer.dataSources.add(dataSource1).show = true;
    //             // viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));
    //         }
    //     } else {
    //         // Hide if currently shown.
    //         // if (viewer.dataSources.contains(dataSource1)) {
    //           document.getElementById("onoff").value="On"
    //           viewer.dataSources.removeAll();
    //           // viewer.dataSources.remove(dataSource1);
    //         // }
    //     }
    // }, false);
////////// KMLのON/OFFボタン ///

// /// 視点を戻すボタン（PINも解除） //////////
//     var buttona = document.getElementById('originview');
//     buttona.addEventListener('click', function() {
//                 viewer.flyTo(viewer.entities, new HeadingPitchRange(0, -1, 50000));
//     }, false);
// ////////// 視点を戻すボタン（PINも解除） ///

/// 視点を戻すボタン（PINも解除） //////////
var buttona = document.getElementById('originview');
buttona.addEventListener('click', function() {
            // viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));

            let currentvalue = document.getElementById('rotate').value;
            if (currentvalue == "回転させる") {
                document.getElementById("rotate").value="Stop"
                viewer.clock.onTick.addEventListener(function(clock) {
                viewer.scene.camera.rotateRight(-0.005);
                });
            };
            viewer.flyTo(viewer.entities, new HeadingPitchRange(0, -1, 50000));

          }, false);
////////// 視点を戻すボタン（PINも解除） ///




// 要素を取得
var yearelements = document.getElementsByName( "yearbutton" ) ;
for ( var a="", i=yearelements.length; i--; ) {
	if ( yearelements[i].checked ) {
		var a = yearelements[i].value ;
		break ;
	}
}



/// 時間セットボタン1 //////////
    var buttontimeset1 = document.getElementById('timeset1');
    buttontimeset1.addEventListener('change', function() {
    if(buttontimeset1.checked){
      data2000.show = false;
      data2005.show = false;
      data2010.show = false;
      data2015.show = false;
      data1995.show = true;
    }
    }, false);
////////// 時間セットボタン1 ///
// /// 時間セットボタン2 //////////
var buttontimeset2 = document.getElementById('timeset2');
buttontimeset2.addEventListener('change', function() {
  if(buttontimeset2.checked){
    data1995.show = false;
    data2005.show = false;
    data2010.show = false;
    data2015.show = false;
    data2000.show = true;
  };
}, false);
// ////////// 時間セットボタン2 ///
// /// 時間セットボタン3 //////////
var buttontimeset3 = document.getElementById('timeset3');
buttontimeset3.addEventListener('change', function() {
  if(buttontimeset3.checked){
    data1995.show = false;
    data2000.show = false;
    data2010.show = false;
    data2015.show = false;
    data2005.show = true;
  };
}, false);
// ////////// 時間セットボタン3 ///
// /// 時間セットボタン4 //////////
var buttontimeset4 = document.getElementById('timeset4');
buttontimeset4.addEventListener('change', function() {
  if(buttontimeset4.checked){
    data1995.show = false;
    data2000.show = false;
    data2015.show = false;
    data2005.show = false;
    data2010.show = true;
  };
}, false);
// ////////// 時間セットボタン4 ///
// /// 時間セットボタン5 //////////
var buttontimeset5 = document.getElementById('timeset5');
buttontimeset5.addEventListener('change', function() {
  if(buttontimeset5.checked){
    data1995.show = false;
    data2000.show = false;
    data2010.show = false;
    data2005.show = false;
    data2015.show = true;
  };
}, false);
// ////////// 時間セットボタン5 ///
// /// 時間セットボタンoff //////////
var buttontimesetoff = document.getElementById('timesetoff');
buttontimesetoff.addEventListener('change', function() {
  if(buttontimesetoff.checked){
    data1995.show = false;
    data2000.show = false;
    data2010.show = false;
    data2005.show = false;
    data2015.show = false;
  };
}, false);
// ////////// 時間セットボタンoff ///


// /// 時間セットボタン1 //////////
//     var yearset1 = this.props.year1+"-11-30";
//     var buttontimeset1 = document.getElementById('timeset1');
//     buttontimeset1.addEventListener('click', function() {
//       data1995.show = !data1995.show;
//       // viewer.clock.currentTime =  JulianDate.fromIso8601(yearset1);
//       // viewer.clock.multiplier = 1;
//       // viewer.clock.shouldAnimate = true; //if it was paused.
//     }, false);
// ////////// 時間セットボタン1 ///
// /// 時間セットボタン1 //////////
//     // var yearset1 = this.props.year1+"-11-30";
//     // var buttontimeset1 = document.getElementById('timeset1');
//     // buttontimeset1.addEventListener('click', function() {
//     //   viewer.clock.currentTime =  JulianDate.fromIso8601(yearset1);
//     //   viewer.clock.multiplier = 1;
//     //   viewer.clock.shouldAnimate = true; //if it was paused.
//     // }, false);
// ////////// 時間セットボタン1 ///

// /// 時間セットボタン2 //////////
//     var yearset2 = this.props.year2+"-11-30";
//     var buttontimeset2 = document.getElementById('timeset2');
//     buttontimeset2.addEventListener('click', function() {
//       viewer.clock.currentTime =  JulianDate.fromIso8601(yearset2);
//       viewer.clock.multiplier = 1;
//       viewer.clock.shouldAnimate = true //if it was paused.
//     }, false);
// ////////// 時間セットボタン2 ///

// /// 時間セットボタン3 //////////
//     var yearset3 = this.props.year3+"-11-30";
//     var buttontimeset3 = document.getElementById('timeset3');
//     buttontimeset3.addEventListener('click', function() {
//       viewer.clock.currentTime =  JulianDate.fromIso8601(yearset3);
//       viewer.clock.multiplier = 1;
//       viewer.clock.shouldAnimate = true //if it was paused.
//     }, false);
// ////////// 時間セットボタン3 ///

// /// 時間セットボタン4 //////////
//     var yearset4 = this.props.year4+"-11-30";
//     var buttontimeset4 = document.getElementById('timeset4');
//     buttontimeset4.addEventListener('click', function() {
//       viewer.clock.currentTime =  JulianDate.fromIso8601(yearset4);
//       viewer.clock.multiplier = 1;
//       viewer.clock.shouldAnimate = true //if it was paused.
//     }, false);
// ////////// 時間セットボタン4 ///

// /// 時間セットボタン5 //////////
//     var yearset5 = this.props.year5+"-11-30";
//     var buttontimeset5 = document.getElementById('timeset5');
//     buttontimeset5.addEventListener('click', function() {
//       viewer.clock.currentTime =  JulianDate.fromIso8601(yearset5);
//       viewer.clock.multiplier = 1;
//       viewer.clock.shouldAnimate = true //if it was paused.
//     }, false);
// ////////// 時間セットボタン5 ///

// /// 時間セットボタンoff //////////
//     var buttontimeset = document.getElementById('timesetoff');
//     buttontimeset.addEventListener('click', function() {
//       viewer.clock.currentTime =  JulianDate.fromIso8601('2022-08-30T11:56:04+08');
//       viewer.clock.multiplier = 1;
//       viewer.clock.shouldAnimate = true //if it was paused.
//     }, false);
// ////////// 時間セットボタンoff ///

/// Navigation /////
var navoptions = {};
navoptions.defaultResetView = Rectangle.fromDegrees(122, 20, 153, 45);
navoptions.enableCompass = true;
navoptions.enableZoomControls = true;
navoptions.enableDistanceLegend = true;
navoptions.enableCompassOuterRing = true;
CesiumNavigation(viewer, navoptions);
///// Navigation ///

  }
 



  
//   componentDidUpdate() {

// /// KMLデータ準備 ///////
//     var dataSource1 = new KmlDataSource.load(this.props.kml_kml,{
//       camera: this.viewer.scene.camera,
//       canvas: this.viewer.scene.canvas,
//     });
// /////// KMLデータ準備 ///

// /// KMLデータ読み込み ///////
// this.viewer.dataSources.add(dataSource1).show = true;
// this.viewer.zoomTo(dataSource1
//   // , new HeadingPitchRange(0, -1, 2000000)
//   );
// /////// KMLデータ読み込み ///

//   }





  // componentWillUnmount() {
  //   this.viewer.destroy();
  // }


}

export default CesiumContainer

